<template>
  <div class="w-full purchase">
    <!-- <button
      class="px-3 py-1 mb-4 text-lg text-gray-200 bg-gray-500 rounded hover:opacity-75"
      @click="editItem(service)"
      v-if="isAuthenticated === 'authorized'"
    >
      EDIT PAGE
    </button> -->
    <h1 class="mb-4 text-2xl uppercase">PURCHASE <span>Service</span></h1>
    <div class="purchase-intro">
      <p>
        Give the gift of “Peace of Mind,” purchase a block of time for a friend
        or family member.
      </p>
      <blockquote>
        Don’t have time for a free consultation with our sales consultant?
        Simply purchase a block of time. Then you can schedule as much or as
        little time as necessary (two-hour minimum per visit).
      </blockquote>
      <div class="mb-3 border rounded-lg shadow-lg border-gray card">
        <div class="px-6 py-4 text-left">
          <ol>
            <li>Select your preferred time block.</li>
            <li>
              Check out with PayPal; if you know the address of the property you
              would like cleaned, please enter it in as the shipping address.
              The service location must be within 20 miles of W176 N9830
              Rivercrest Dr Suite 102, Germantown, WI 53022.
            </li>
            <li>
              After you purchase your gift certificate or time block, you will
              receive an e-mail from us to set up your service date and to
              answer any questions you may have.
            </li>
          </ol>
        </div>
      </div>
      <div
        id="purchase-options"
        class="grid grid-cols-1 gap-3 mt-12 sm:grid-cols-2"
      >
        <!-- Two hours of service -->
        <div class="p-4 mb-3 border rounded-lg shadow-lg border-gray card">
          <h3 class="text-xl text-center text-gray-600 uppercase font-b">
            Two hours of service
          </h3>
          <h2 class="text-3xl font-bold text-center">$90.00 USD</h2>
          <div class="text-center">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="cmd" value="_s-xclick" /><br />
              <input
                type="hidden"
                name="hosted_button_id"
                value="CQQ5WR4A8Z4VY"
              /><br />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              /><br />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              /><br />
            </form>
          </div>
        </div>
        <!-- Five hours of service -->
        <div class="p-4 mb-3 border rounded-lg shadow-lg border-gray card">
          <h3 class="text-xl text-center text-gray-600 uppercase font-b">
            Four hours of service
          </h3>
          <h2 class="text-3xl font-bold text-center">$176.00 USD</h2>
          <div class="text-center">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="cmd" value="_s-xclick" /><br />
              <input
                type="hidden"
                name="hosted_button_id"
                value="YUGNTGBLPGJX8"
              /><br />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              /><br />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              /><br />
            </form>
          </div>
        </div>
        <!-- Ten hours of service $410.00 USD -->
        <div class="p-4 mb-3 border rounded-lg shadow-lg border-gray card">
          <h3 class="text-xl text-center text-gray-600 uppercase font-b">
            Ten hours of service
          </h3>
          <h2 class="text-3xl font-bold text-center">$410.00 USD</h2>
          <div class="text-center">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="cmd" value="_s-xclick" /><br />
              <input
                type="hidden"
                name="hosted_button_id"
                value="DFC94QDTS78QW"
              /><br />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              /><br />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              /><br />
            </form>
          </div>
        </div>
        <!-- Twenty hours of service $800.00 USD -->
        <div class="p-4 mb-3 border rounded-lg shadow-lg border-gray card">
          <h3 class="text-xl text-center text-gray-600 uppercase font-b">
            Twenty hours of service
          </h3>
          <h2 class="text-3xl font-bold text-center">$800.00 USD</h2>
          <div class="text-center">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="cmd" value="_s-xclick" /><br />
              <input
                type="hidden"
                name="hosted_button_id"
                value="SPHJP24FEM33E"
              /><br />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              /><br />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              /><br />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <admin-services
    :service="service"
    :toggleModal="toggleModal"
    v-if="services_show"
  ></admin-services>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import { getAllOfPageType, isAuthenticated } from "../firebase.js";
import AdminServices from "@/admin/AdminServices.vue";

export default {
  inject: ["global"],
  setup() {
    const service = ref({
      id: "0",
      page: "services",
      type: "concierge",
      sortOrder: 0,
      summary: "",
      subtitle: "",
      wysiwyg: "",
      linktitle: "",
    });
    const services_show = ref(false);
    const selectedItem = ref({});
    onBeforeMount(() => {
      getAllOfPageType("services", "concierge").then((data) => {
        if (data.length) service.value = data[0];
      });
    });
    const toggleModal = (section) => {
      eval(`${section}_show`).value = !eval(`${section}_show`).value;
    };
    const editItem = (item) => {
      const editorVars = ["summary", "wysiwyg", "outro"];
      for (const e in editorVars) {
        if (item && item[e]) {
          item[e] = item[e]
            .replace(/\\n \+\n/g, "")
            .replace(/    /g, "")
            .replace(/\n/g, "");
        }
      }
      selectedItem.value = item;
      service.value = item;
      toggleModal("services");
    };
    return {
      service,
      services_show,
      getAllOfPageType,
      isAuthenticated,
      editItem,
      selectedItem,
      toggleModal,
      AdminServices,
    };
  },
};
</script>

<style lang="scss">
.img-holder {
  max-width: 460px;
}
.header-description p {
  margin-bottom: 1rem;
}
.purchase-intro {
  text-align: left;
}
.purchase-intro p {
  margin-bottom: 16px;
}
.purchase-intro ol {
  list-style: decimal;
  margin: 0 0 12px 2rem;
}
.purchase-intro blockquote {
  background: #f7f7f7;
  padding: 20px 25px 25px;
  margin: 30px 30px;
  border-left: 4px solid #265aa9;
}
#purchase-options form {
  margin-top: -30px;
}
.purchase h1 {
  color: #265aa9;
}
</style>